import React, { useRef } from "react";
import Stack from "@mui/material/Stack";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "react-bootstrap/Button";
import { Typography } from "@material-ui/core";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { styled } from "@mui/material/styles";
import Form from "./Form";
import SolutionCard from "./SolutionCard";
// import QA from "./QA";
import img from "./img/website.png";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-serif/100.css";
import "@fontsource/libre-baskerville";
import "@fontsource/cutive-mono";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(3),
  textAlign: "center",
}));

function Code() {
  const formRef = useRef(null);
  const inputRef = useRef(null);

  function handleClick() {
    window.sa_event("goSignUp_frImg");
    formRef.current.scrollIntoView();
    inputRef.current && inputRef.current.focus();
  }

  return (
    <>
      <div
        style={{
          height: 50,
          width: "100%",
          backgroundColor: "white",
        }}
      />
      <Box
        style={{
          padding: "5%",
          backgroundColor: "white",
          fontFamily: "Roboto Serif",
          color: "#035194",
        }}
      >
        <Stack
          spacing={6}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ width: "100%" }}>
            <Typography
              variant="h4"
              component={"span"}
              style={{ fontFamily: "Libre Baskerville" }}
            >
              variate.energy
              <span style={{ fontFamily: "Cutive Mono", color: "#155370" }}>
                /code
              </span>
            </Typography>
            <Typography
              variant="h6"
              style={{ fontFamily: "Roboto", color: "black", fontWeight: 400 }}
            >
              A renewable-first simulation and optimization framework
            </Typography>
          </div>
          <div style={{ height: "12px" }}></div>

          <div style={{ width: "80vw", margin: "auto" }}>
            <img
              src={img}
              alt="variate.energy /code"
              style={{
                width: "100%",
                margin: "auto",
              }}
            />
          </div>
          <Button
            style={{
              backgroundColor: "#035194",
              color: "white",
              border: "none",
            }}
            onClick={handleClick}
          >
            Start Free Trial
          </Button>
          <div style={{ height: "12px" }}></div>
          <div>
            <SolutionCard />
          </div>
          <Item style={{ width: "100%" }} ref={formRef} elevation={3}>
            <Typography
              variant="h4"
              component={"span"}
              style={{
                color: "#155370",
                fontFamily: "Roboto Serif",
              }}
            >
              Create a free account.
            </Typography>
            <br></br>
            <Typography
              component={"span"}
              variant="body1"
              style={{ lineHeight: "1.5" }}
            >
              Get 30 days free trial of our Beta version. No credit card
              required.
            </Typography>
            <div style={{ height: "24px" }}></div>
            <Form childRef={inputRef} />
          </Item>
          {/* <div style={{ width: "100%", textAlign: "left" }}>
            <Typography
              variant="h4"
              style={{ color: "#155370", fontFamily: "Roboto Serif" }}
            >
              Frequently Asked Questions.
            </Typography>
            <div style={{ height: "24px" }}></div>

            <QA />
          </div> */}

          <div style={{ width: "100%", textAlign: "left" }}>
            <Typography
              variant="h4"
              component={"span"}
              style={{ color: "#155370", fontFamily: "Roboto Serif" }}
            >
              Get in touch.
            </Typography>
            <div style={{ height: "12px" }}></div>

            <Typography
              variant="body1"
              component={"span"}
              style={{ lineHeight: "1.5", color: "black" }}
            >
              Not sure yet, interested in learning more or have questions?{" "}
              <br />
              You can contact us via:
            </Typography>

            <div style={{ height: "12px" }}></div>
            <Typography
              variant="body1"
              component={"span"}
              style={{ lineHeight: "2.5", color: "black" }}
            >
              <AlternateEmailIcon />
              {"    "} info@variate.energy
              <br />
              <PhoneIcon />
              {"    "} +49 30 65214182
              <br />
              <ChatBubbleOutlineIcon /> {"    "}{" "}
              linkedin.com/showcase/variate.energy
              <br />
            </Typography>
          </div>
        </Stack>
      </Box>
    </>
  );
}

export default Code;

import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import "./Home.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Detail() {
  const classes = useStyles();

  return (
    <div className="container-box section" id="sec-contact">
      <div className={classes.root}>
        <Grid container spacing={2} style={{ width: "98%", margin: "auto" }}>
          <Grid item xs={12} sm={12}>
            <Paper
              className={classes.paper}
              elevation={0}
              spacing={0}
              style={{ paddingBottom: 0, backgroundColor: "inherit" }}
            >
              <Typography
                variant="h4"
                align="left"
                paragraph={true}
                style={{ color: "black", fontWeight: "500" }}
              >
                Our Services
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography
                variant="subtitle2"
                align="left"
                paragraph={true}
                style={{ color: "#4caf50" }}
              >
                <CheckIcon />
                Data Download
              </Typography>
              <Typography
                variant="h6"
                align="left"
                paragraph={true}
                style={{ color: "black", fontWeight: "500" }}
              >
                Get over 40 years of historical weather data
              </Typography>
              <Typography variant="body1" align="left" paragraph={true}>
                Leverage information from over 40 years of historical weather
                data to better assess site-specific weather conditions.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography
                variant="subtitle2"
                align="left"
                paragraph={true}
                style={{ color: "#4caf50" }}
              >
                <CheckIcon />
                In-depth Analysis
              </Typography>
              <Typography
                variant="h6"
                align="left"
                paragraph={true}
                style={{ color: "black", fontWeight: "500" }}
              >
                Analyse On-Premise Renewable Energy Systems
              </Typography>
              <Typography variant="body1" align="left" paragraph={true}>
                Optimize renewable energy components together with storage to
                fulfill your energy requirements as efficiently and
                cost-effective as possible.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography
                variant="subtitle2"
                align="left"
                paragraph={true}
                style={{ color: "#4caf50" }}
              >
                <CheckIcon />
                Risk Assessment
              </Typography>
              <Typography
                variant="h6"
                align="left"
                paragraph={true}
                style={{ color: "black", fontWeight: "500" }}
              >
                Future-proof your renewable energy assets
              </Typography>
              <Typography variant="body1" align="left" paragraph={true}>
                Increase resilience and maximize profit by understanding risks
                associated with intermittent energy generation. Find out how{" "}
                <span style={{ textDecoration: "underline" }}>future</span>{" "}
                weather conditions will impact your investment.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography
                variant="subtitle2"
                align="left"
                paragraph={true}
                style={{ color: "#4caf50" }}
              >
                <CheckIcon />
                Enhance Resilience
              </Typography>
              <Typography
                variant="h6"
                align="left"
                paragraph={true}
                style={{ color: "black", fontWeight: "500" }}
              >
                Ensure 100% Renewable
              </Typography>
              <Typography variant="body1" align="left" paragraph={true}>
                Analyse how future weather conditions impact your targets to
                source 100% renewable and get insights into increasing
                resilience to fluctuating weather conditions.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Detail;

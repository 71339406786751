import React from "react";
import Code from "./Code";
import { Nav } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "react-bootstrap/Button";
import img from "./img/softwareMockup.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Product() {
  const classes = useStyles();
  return (
    <>
      <Switch>
        <Route path="/code" component={Code} />
      </Switch>
      <div
        className="section"
        id="sec-contact"
        style={{ backgroundColor: "white" }}
      >
        <div className={classes.root}>
          <Grid
            container
            spacing={3}
            direction="row"
            style={{ justifyContent: "beginning", alignItems: "center" }}
          >
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ backgroundColor: "inherit" }}
              >
                <img
                  src={img}
                  alt="placeholder"
                  style={{ maxWidth: "80%", margin: "auto" }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                elevation={0}
                style={{ backgroundColor: "inherit" }}
              >
                <Typography
                  variant="subtitle1"
                  align="left"
                  paragraph={true}
                  style={{ color: "#4caf50" }}
                >
                  Renewable-first Energy Modelling
                </Typography>
                <Typography
                  variant="h4"
                  align="left"
                  paragraph={true}
                  style={{ color: "black", fontWeight: "500" }}
                >
                  variate.energy /code
                </Typography>
                <Typography variant="body1" align="left" paragraph={true}>
                  variate.energy code combines the flexibility of custom
                  software with instant access to pre-trained machine learning
                  models purpose-built for renewable energy.
                  <br />
                  <br />
                  Get started today and accelerate your energy solutions with
                  just a few lines of code!
                </Typography>
                <LinkContainer to="/code" style={{ padding: 0, margin: 0 }}>
                  <Nav.Link>
                    <Button
                      className="button"
                      variant="outline-secondary"
                      onClick={() => {
                        window.sa_event("goToSolution_frProduct");
                      }}
                      style={{ float: "left", margin: "0" }}
                    >
                      MORE
                    </Button>
                  </Nav.Link>
                </LinkContainer>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Product;

import React from "react";
import { Typography } from "@material-ui/core";
import ContactForm from "./ContactForm";

function Banner() {
  return (
    <div
      className="container-box section"
      id="sec-contact"
      style={{ backgroundColor: "white" }}
    >
      <Typography
        variant="h4"
        align="left"
        paragraph={true}
        style={{
          color: "black",
          fontWeight: "500",
          margin: "10px 20px",
          padding: "10px 25px",
        }}
      >
        {" "}
        Get in touch
      </Typography>
      <div style={{ margin: "10px 20px", padding: "10px 25px" }}>
        <ContactForm />
      </div>
    </div>
  );
}

export default Banner;

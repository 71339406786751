import React from "react";
import { Typography } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Box from "@material-ui/core/Box";
import "@fontsource/roboto-serif/200.css";

function SolutionCard() {
  return (
    <Box style={{ width: "100%", textAlign: "center", margin: "auto" }}>
      <Typography
        variant="h4"
        component={"span"}
        style={{
          color: "#155370",
          fontFamily: "Roboto Serif",
        }}
      >
        The business of energy is changing.
      </Typography>
      <div style={{ height: "12px" }}></div>

      <Typography
        component={"span"}
        variant="body1"
        style={{ color: "black", lineHeight: "1.5" }}
      >
        Our renewable-first framework provides you with the necessary tools to
        adapt to the challenges of a changing energy landscape.
      </Typography>
      <CardDeck
        style={{
          padding: "10px 20px",
          marginTop: "28px",
          marginBottom: "28px",
        }}
      >
        <Card className="text-center">
          <Card.Header as="h5" style={{ color: "#155370" }}>
            No Limitations, Full Flexibility
          </Card.Header>
          <Card.Body>
            <Card.Title>
              <Typography
                variant="body1"
                component={"span"}
                style={{ color: "black", lineHeight: "1.5", textAlign: "left" }}
              >
                The energy sector is rapidly changing with new technologies and
                new business models set to dominate the future energy landscape.
              </Typography>
            </Card.Title>
            <Card.Text as="div">
              <Typography
                variant="body1"
                component={"span"}
                style={{ color: "black", lineHeight: "1.5", textAlign: "left" }}
              >
                variate.energy code supports energy experts in keeping up with
                these changes through an intuitive coding interface, which
                provides full flexibility to quickly explore and evaluate new
                technologies or business models in just a few lines of code.
                <br />
              </Typography>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="text-center">
          <Card.Header as="h5" style={{ color: "#155370" }}>
            Runs in the Cloud
          </Card.Header>
          <Card.Body>
            <Card.Text as="div">
              <Card.Title>
                <Typography
                  variant="body1"
                  component={"span"}
                  style={{
                    color: "black",
                    lineHeight: "1.5",
                    textAlign: "left",
                  }}
                >
                  With increasing complexity of energy projects, computing power
                  becomes vital for fast simulation and robust optimization.
                </Typography>
              </Card.Title>
              <Typography
                variant="body1"
                component={"span"}
                style={{ color: "black", lineHeight: "1.5", textAlign: "left" }}
              >
                variate.energy code runs on fast and scalable compute clusters
                to speed up any modelling and optimization tasks. No need to
                build-up and maintain your own cloud infrastructure. Get the
                full benefits of cloud-computing to accelerate your workflows
                with variate.energy code.
              </Typography>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="text-center">
          <Card.Header as="h5" style={{ color: "#155370" }}>
            Built-in Algorithms
          </Card.Header>
          <Card.Body>
            <Card.Title>
              <Typography
                variant="body1"
                component={"span"}
                style={{
                  color: "black",
                  lineHeight: "1.5",
                  textAlign: "left",
                }}
              >
                Machine-learning algorithms provide insights for decision-making
                in highly dynamic and uncertain environments.
              </Typography>
            </Card.Title>
            <Card.Text as="div">
              <Typography
                variant="body1"
                component={"span"}
                style={{ color: "black", lineHeight: "1.5", textAlign: "left" }}
              >
                variate.energy code's toolbox includes ready-to-use
                machine-learning algorithms tailored for renewable energy
                applications. <br />
                We continuously update the toolbox together with training
                material to foster data science capabilities in your team.
              </Typography>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardDeck>
    </Box>
  );
}

export default SolutionCard;

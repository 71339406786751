import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";

import { useForm, Controller } from "react-hook-form";
import { withStyles } from "@material-ui/core/styles";

const CustomCheckbox = withStyles({
  root: {
    color: "#155370",
    "&$checked": {
      color: "#155370",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid darkgray",
  boxShadow: 24,
  p: 4,
};

const Form = ({ childRef }) => {
  const { handleSubmit, control } = useForm();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const onSubmit = (value) => {
    window.sa_event("submit_solutionForm");

    axios.defaults.headers.post["Content-Type"] =
      "application/json; charset=UTF-8";

    axios
      .post(
        "https://prod-17.germanywestcentral.logic.azure.com:443/workflows/25d2b368e81e46bb984cdc62039c1e72/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=WvY8p9O0TIpWOtNj30_mhTHYw8FqgkmfqsanmghK9EA",
        value,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            // Accept: "application/json; charset=utf-8",
          },
        }
      )
      .then(function (response) {
        handleOpenModal();
        setMessage(
          "Thank you! Please check your inbox for a confirmation email. Our team will be in touch shortly."
        );
      })
      .catch(function (error) {
        handleOpenModal();
        setMessage(
          "Error. Please try again. If the error persists please send an email to info@variate.energy stating your contact information and we will send you your login details then."
        );
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={5} style={{ justifyContent: "space-between" }}>
          <Grid item xs={12} md={3}>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputRef={childRef}
                />
              )}
              rules={{ required: "First name required" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  fullWidth
                  label="Last Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: "Last name required" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name="jobTitle"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="Job Title"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="jobTitle"
                />
              )}
              rules={{ required: "Job title required" }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="Email"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="email"
                />
              )}
              rules={{ required: "Email required" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="company"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="Company / Institute / Affiliation"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="company"
                />
              )}
              rules={{ required: "Company / Institute / Affiliation required" }}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="checkbox"
              control={control}
              defaultValue={false}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  value="end"
                  control={
                    <CustomCheckbox
                      checked={!!value}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                    />
                  }
                  label="I would like to get a personal demo."
                  labelPlacement="end"
                  style={{ color: "black", width: "100%" }}
                />
              )}
            />
            <Controller
              name="checkBoxConfirm"
              control={control}
              defaultValue={false}
              required
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControlLabel
                  value="end"
                  required
                  control={
                    <CustomCheckbox
                      required
                      checked={!!value}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                    />
                  }
                  label={
                    <>
                      <label>
                        I confirm that I have read and agree to the
                        <a
                          href="https://variate.energy/#/Privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                          <span style={{ fontWeight: "300" }}>. *</span>
                        </a>
                      </label>
                    </>
                  }
                  labelPlacement="end"
                  style={{ color: "black", width: "100%" }}
                />
              )}
              rules={{
                required:
                  "Please confirm that you have read and agree to our Privacy Policy. ",
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              type="submit"
              style={{ backgroundColor: "#035194", color: "white" }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
      {open ? (
        <Modal open={open} onClose={handleCloseModal}>
          <Box sx={style}>{message}</Box>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};
export default Form;

import React from "react";
import Image from "react-bootstrap/Image";
import Typography from "@material-ui/core/Typography";
import { Nav } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import background from "./backgroundVariate_clipped.jpg";
import Button from "react-bootstrap/Button";
import Product from "./Product";
import Detail from "./Detail";
import Code from "./Code";

import About from "./About";
import Banner from "./Banner";
import "./Home.css";

function Home() {
  return (
    <>
      <Switch>
        <Route path="/code" component={Code} />
      </Switch>
      <div id="sec-home" style={{ paddingTop: "7vh" }}>
        <Image src={background} fluid style={{ width: "auto" }} />
        <div className="section">
          <Typography variant="h3" className="mainTitle">
            Advanced Weather Simulations <br></br>
            for <span className="boldUp">Renewable Energy</span>
          </Typography>

          <Typography
            variant="h6"
            className="mainSubTitle"
            style={{ lineHeight: "1.2", maxWidth: "75%" }}
          >
            variate.energy enables a weather-centric risk assessment of
            renewable energy projects, evaluation of portfolios, and simulations
            of entire systems.
          </Typography>
          <LinkContainer to="/code" style={{ padding: 0, margin: 0 }}>
            <Nav.Link>
              <Button
                className="button"
                variant="outline-secondary"
                style={{ margin: "10px 20px", padding: "10px 25px" }}
                onClick={() => {
                  window.sa_event("goToSolution_frHeader");
                }}
              >
                GET ACCESS
              </Button>
            </Nav.Link>
          </LinkContainer>
        </div>
        <About />
        <Product />
        <Detail />
        <Banner />
      </div>
    </>
  );
}

export default Home;
